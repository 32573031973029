import React from 'react';
import { Container } from 'react-bootstrap';

const DemoSites = () => {
    return (
        <Container>
            <div className="sites site-extensions shadow p-3 mb-5 bg-white rounded">
                <a className="black" href="extensions">
                    Internal extensions
                </a>
            </div>
            <div className="sites site-pablosyogashop shadow p-3 mb-5 bg-white rounded">
                <a
                    className="black"
                    href="https://www.pablosyogashop.com/"
                >
                    Pablo's Yoga Shop
                </a>
            </div>
            <div className="sites site-121auto shadow p-3 mb-5 bg-white rounded">
                <a className="black" href="https://www.121auto.com/">
                    Pablo's Yoga Shop
                </a>
            </div>
        </Container>
    );
};

export default DemoSites;
