import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row } from 'react-bootstrap';

import List from './List';
import withListLoading from './WithListLoading';

const Extensions = () => {
    const ListLoading = withListLoading(List);
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });
    useEffect(() => {
        setAppState({ loading: true });
        const config = {
            headers: {
                'x-auth-token':
                    'fe1e4b54c266e2f4caab49f7a2d12864c40a77ee171ba1cbe22a089a0a445e9c',
            },
        };
        const apiUrl = `https://eagle.121ecommerce.com/api/v1/extensions`;
        axios.get(apiUrl, config).then((extensions) => {
            const allExtensions = extensions.data.result.extensions;
            setAppState({
                loading: false,
                extensions: allExtensions,
            });
        });
    }, [setAppState]);

    return (
        <Row>
            <ListLoading
                isLoading={appState.loading}
                extensions={appState.extensions}
            />
        </Row>
    );
};

export default Extensions;
