import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
} from 'react-bootstrap';

const Header = (props) => {
    return (
        <Navbar bg="light">
            <Navbar.Brand href="#home" className="mr-auto">
                <img
                    alt=""
                    src="/121ecommerce.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
                121 Master Panel
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="extensions">Extensions</Nav.Link>
                    <NavDropdown
                        title="Demo sites"
                        id="basic-nav-dropdown"
                    >
                        <NavDropdown.Item
                            href="https://www.pablosyogashop.com/"
                            target="_blank"
                        >
                            Pablo's Yoga Shop
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href="https://www.121auto.com/"
                            target="_blank"
                        >
                            121 Autos
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
            <Form inline className="d-none d-sm-block">
                <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                />
                <Button variant="outline-info">Search</Button>
            </Form>
        </Navbar>
    );
};

export default Header;
