import React from 'react';
import Card from './Card';

const List = (props) => {
    const { extensions } = props;
    if (!extensions || extensions.length === 0)
        return <p>No extensions, sorry</p>;

    return extensions.map((extension) => {
        return <Card key={extension.name} extension={extension} />;
    });
};

export default List;
