import './App.css';
import Header from './components/Header';
import { Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';

import DemoSites from './components/DemoSites';
import Extensions from './components/Extensions';

function App() {
    return (
        <Container>
            <Header />
            <Routes>
                <Route path="/" element={<DemoSites />} />
                <Route path="extensions" element={<Extensions />} />
            </Routes>
        </Container>
    );
}

export default App;
