import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import {
    faCodeBranch,
    faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

const Card = (props) => {
    const { extension } = props;
    return (
        <Col className="col-12 col-sm-8 col-md-6 col-lg-4">
            <div className="card h-350">
                <div className="card-img-container">
                    <img
                        className="card-img"
                        src={
                            extension.images.length > 0
                                ? `https://eagle.121ecommerce.com/thumbnail?file=/uploads/${extension.images[0].imageFile}&width=348&height=215&ignoreCache=0&defaultFile=`
                                : '121ecommerce.png'
                        }
                        alt={extension.name}
                    />
                    <div className="overlay">
                        {extension.categories.map((category) => (
                            <span
                                key={category.id}
                                className="badge bg-success text-uppercase"
                            >
                                {category.name}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{extension.name}</h4>
                    <small>
                        <FontAwesomeIcon icon={faCodeBranch} />{' '}
                        {extension.supportedVersions}
                    </small>
                    <p className="card-text">
                        {extension.description}
                    </p>
                    {extension.links.map((link) => (
                        <a
                            key={link.id}
                            href={link.url}
                            className="btn btn-primary"
                            rel="noreferrer"
                            target="_blank"
                        >
                            {link.label}
                        </a>
                    ))}
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <div className="views">
                        <small>
                            <FontAwesomeIcon icon={faCalendar} />{' '}
                            <Moment format="MMM Do YYYY">
                                {extension.versionUpdatedDate}
                            </Moment>
                        </small>
                    </div>
                    <div className="stats">
                        {extension.currentVersion}
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default Card;
